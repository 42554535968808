<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong> Unutilised Tissue Report</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <!-- Start date template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY"  readonly hide-details
              outlined dense v-bind="attrs" v-on="on"></v-text-field> 
          </template>
          <v-date-picker v-model="filter.date_from" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <!-- End data template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY"  readonly hide-details
              outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_to" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <v-col cols="12" md="3" class="pl-0">
        <v-btn color="primary" class="mr-2" @click="reportExport()"> Export </v-btn>
        <v-btn color="primary" @click="refreshButton()">Reset</v-btn>
      </v-col>
    
    </v-row>


    <!-- <v-data-table
      :headers="headers"
      :items="FinancialReports"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
    >
      <template #[`item.operate`]="{}">
        <v-btn color="primary" class="mr-3" @click="aa()"> Print </v-btn>
        <v-btn color="error" text @click="vv()"> Delete </v-btn>
      </template>
    </v-data-table> -->

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import FileSaver from 'file-saver'
import VueJsonToCsv from 'vue-json-to-csv'
import { api } from '@/config/config'
import moment from 'moment'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
   components: {
    VueJsonToCsv,
  },
  data() {
    return {
      YearFrom:['2022','2021','2020','2019','2018','2017','2016','2015'],
      YearTo:['2022','2021','2020','2019','2018','2017','2016','2015'],
       Monthitem:['January','February','March','April','May','June','July','August','September','October','November','December'],
       BranchesList:[{city: ""}],
       tpyeitem:['HCRP','Voluntary'],
       YearT:[1,2,3,4],
       Edctitem:[{relation_with_caller_other_name:""}],
      aa: '',
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      Reports: [],
      monthReports:[],
      filter: {
        date_from: '',
        date_to: ''
      },
       csvLabels: {
        center_name: { title: 'Cernter Name' },
        collection: { title: 'Collection'},

      },
      fileName: 'USER-LIST-' + moment().valueOf(),
       csvListData: [],

      headers: [
        { text: 'Cernter Name', value: 'center_name', width: '250px' },
        { text: 'Collection', value: 'collection', width: '250px' },
        
        // { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        // { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
        // { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
      },
      deep: true,
    },
  },
  computed: {
    dateFormateTo() {
      return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
    },

    dateFormateFrom() {
      return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
    },
  },

  async mounted() {
  },

  methods: {
    refreshButton() {
      this.filter.date_from = '',
        this.filter.date_to = ''
    },

    async reportExport() {
      if(this.filter.date_from == "" || this.filter.date_from === null){
        return false,
        this.snackbarText = "Please Fill Date",
        this.snackbar = true
      }
      if(this.filter.date_to == "" || this.filter.date_to === null){
        return false,
        this.snackbarText = "Please Fill Date",
        this.snackbar = true
      }
      this.listLoading = true
      const data = {
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
      }
      try {
        const response = await api.post(`reports/unutilised_tissue_report`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          
        })
        if(response.data.message == "Successfully fetched"){
          this.Reports = response.data
          var  value = `https://api.ebsr.in${this.Reports.path}`
          FileSaver.saveAs(value, 'unutilized_tissue');
        }else {
          this.donorReport = []
          this.totallist = 0
        }
        this.snackbarText = response.data.message,
        this.snackbar = true
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
